<template>
  <v-app>
    <Admin />
  </v-app>
</template>

<script>
import Admin from "./../components/admin/Admin";

export default {
  components: {
    Admin,
  },

  data: () => ({}),
};
</script>
