<template>
  <v-main id="team">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h2 class="modernline text-center text-sm-left">{{$t('TEAM')}}</h2>
          <div v-html="$t('TEAM_TEXT')"></div>
        </v-col>
        <v-col cols="6" md="3" class="image">
          <v-img :src="team1" max-width="210" aspect-ratio="0.5" />
        </v-col>
        <v-col cols="6" md="3" class="image">
          <v-img :src="team2" max-width="210" aspect-ratio="0.5" />
        </v-col>
      </v-row>
    </v-container>
    <v-parallax class="parallax" light :src="parallax" height="250">
      <v-layout align-center column justify-center> </v-layout>
    </v-parallax>
  </v-main>
</template>

<script>
import globalTranslations from "../locales/global";

export default {
  i18n: {
    locale: "si",
    sharedMessages: globalTranslations,
  },
  name: "Team",
  data() {
    return {
      currentPage: "si",
      parallax: require("../assets/content/team/parallax.webp"),
      team1: require("../assets/content/team/1.webp"),
      team2: require("../assets/content/team/2.webp"),
    };
  },
};
</script>

<style lang="scss" scoped>
.parallax {
  margin: 3rem 0;
  font-size: 2.5rem;
}
.image div {
  margin: 0 auto;
}
</style>
