<template>
  <v-footer dark>
    <v-container class="text-center text-md-left">
      <v-row>
        <v-col md="6" cols="12">
          <h5 class="modernline title-small hidden-sm-and-down">
            Gostilna Mencinger
          </h5>
          <div v-if="isSlovenian()">
            <p>
              <img
                :src="require('../assets/content/footer/eu.png')"
                height="70"
                style="margin-right: 5px"
              />
              <img
                :src="require('../assets/content/footer/mgrt.jpg')"
                height="70"
              />
            </p>
            <v-dialog v-model="dialog2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  Politika zasebnosti
                </v-btn>
              </template>
              <v-card>
                <v-toolbar dark>
                  <v-btn icon dark @click="dialog2 = false">
                    <v-icon>fa-solid fa-chevron-left</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                  <h3>Katere vaše informacije zbiramo?</h3>
                  <p>
                    Za potrebe spletne rezervacije ali povpraševanja se shranijo
                    kontaktni podatki (ime in priimek ter vaša telefonska
                    številka oziroma elektronski naslov).
                  </p>
                  <h3>Za kaj uporabljamo vaše podatke?</h3>
                  <p>
                    Vaše podatke lahko uporabimo za odgovarjanje na vprašanja
                    oz. realizacijo poslane rezervacije ali vprasanja. Vaši
                    podatki se ne bodo prodajali, zamenjali, prenašali ali dali
                    kateri koli drugi družbi iz kakršnega koli razloga.
                  </p>
                  <h3>Kako varujemo vaše podatke?</h3>
                  <p>
                    Vaši podatki vezani na rezervacijo so za potrebe izvedbe
                    le-te shranjeni v rezervacijskem sistemu, ki ga uporabljamo
                    za vodenje rezervacij (ponudnik sistema je podjetje diti
                    media s.p.). Dostop do podatkov imajo samo osebe zaposlene v
                    podjetju in imajo za to dodeljene pravice (osebje, podpora).
                    Vsi podatki med spletno stranjo in rezervacijskim sistemom
                    se prenašajo po varni HTTPS povezavi.
                  </p>
                  <h3>Ali uprabljamo piškotke?</h3>
                  <p>Ne. Spletna stran za delovanje ne potrebuje piškotov.</p>
                  <h3>Razkritje tretjim osebam</h3>
                  <p>
                    Vaših osebnih podatkov ne prodajamo, ne trgujemo z njimi ali
                    jih kako drugače prenešamo zunanjim osebam.
                  </p>
                  <h3>Spremembe našega pravilnika zasebnosti</h3>
                  <p>
                    Če se odločimo za spremembo naše politike zasebnosti, bomo
                    te spremembe objavili na tej strani.
                  </p>
                  <h3>Kontakt z nami</h3>
                  <p>
                    Če imate kakršna koli vprašanja o tem pravilniku zasebnosti,
                    se obrnite na nas preko kontaktne strani ali objavljenih
                    kontaktov.
                  </p></v-card-text
                >
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  Energetska sanacija
                </v-btn>
              </template>
              <v-card>
                <v-toolbar dark>
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>fa-solid fa-chevron-left</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5"><h1>Energetska sanacija gostilne Mencinger</h1>
                <p>Podjetje Gostilna Mencinger, Matej Štefanec s.p., je na javnem razpisu "Podpora mikro, malim in srednje velikim 
                  podjetjem s področja zurizma za povečanje snovne in energetske učinkovitosti" uspešno pridobilo sofinanciranje za projekt peracije "Energetska sanacija Gostilne Mencinger".</p>
                  <p>Namen javnega razpisa je izboljšati konkurenčnost mikro, malih in srednje velikih podjetjih s področja turizma, s spodbujanjem ukrepov za rabo obnovljivih virov energije in zmanjšanje rabe energije in snovi. </p>
                  <p>Naložbo sofinacirata Republika Slovenija in Evropska unija iz Evropskega sklada za razvoj. Povezava na spletno stran EKP v Sloveniji: <a href="www.eu-skladi.si" target="_blank">www.eu-skladi.si</a></p></v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
        <v-col md="3" cols="12">
          <p>
            <strong class="mb-1">Matej Štefanec s.p.</strong><br />
            Črešnjevci 93
            <br />Gornja Radgona
            <br />
            {{ $t("Slovenija") }}
            <br />
          </p>
          <p>
            T:
            <a href="tel:+386 40 278 700">+386 40 278 700</a>
            <br />E:
            <a href="mailto:info@gostilna-mencinger.si"
              >info@gostilna-mencinger.si</a
            >
          </p>
        </v-col>
        <v-col md="3" cols="12">
          <h5>
            <span>{{ $t("Odpiralni čas") }}</span>
          </h5>
          <p>
            {{ $t("Ponedeljek") }}
            <br />{{ $t("Zaprto") }}
          </p>
          <p>
            {{ $t("Torek - Petek") }}
            <br />09:00 – 20:30
          </p>
          <p>
            {{ $t("Sobota") }}
            <br />
            11:00 - 20:30
          </p>
          <p>
            {{ $t("Nedelja") }}<br />
            11:00 - 15:00
          </p>
        </v-col>
      </v-row>
      <v-row class="copyright">
        <v-col cols="12" md="8" class="body-2 text-center text-md-left">
          {{ $t("Vse pravice pridržane") }} © {{ new Date().getFullYear() }},
          Gostilna Mencinger
          <br />
          {{ $t("Produkcija") }}
          <a href="https://www.facebook.com/diti.media" target="_blank"
            >diti. media</a
          >
        </v-col>
        <v-col cols="12" md="4" class="text-center text-md-right social">
          <v-btn
            v-for="icon in socials"
            :key="icon.img"
            class="mx-3"
            icon
            target="_blank"
            :href="icon.href"
          >
            <v-icon size="24px">{{ icon.img }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import footerTranslations from "../locales/footer"; // import common locale messages

export default {
  i18n: {
    locale: "si",
    sharedMessages: footerTranslations,
  },
  methods: {
    isSlovenian() {
      return this.$i18n.locale === "si";
    },
  },
  data: () => ({
    dialog: false,
    dialog2: false,
    socials: [
      {
        img: "fab fa-facebook",
        href: "http://facebook.com/GostilnaMencingerGornjaRadgona",
      },
      {
        img: "fab fa-instagram",
        href: "https://www.instagram.com/gostilnamencinger/",
      },
      {
        img: "fab fa-tripadvisor",
        href: "https://www.tripadvisor.com/Restaurant_Review-g2202555-d11620220-Reviews-Gostilna_Mencinger-Gornja_Radgona_Styria_Region.html",
      },
    ],
  }),
};
</script>

<style scoped>
.title-small {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}
.footer {
  background-color: #333;
}
.footer h5 {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
.copyright {
  padding-top: 10px;
  color: #b1b1b1;
  border-top: 2px solid #1d1d1d;
}
.social a {
  color: #b1b1b1 !important;
}
</style>
