import { getAuth } from "firebase/auth";

export const getCurrentUser = async () => await getAuth().currentUser;

export const isAuthenticated = async () => await !getCurrentUser();

export const authAdmin = async () => {
  const currentUser = await getCurrentUser();
  const token = await currentUser.getIdToken(true);

  return token;
};
