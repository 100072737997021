<template>
  <v-app>
    <Navigation />
    <Landing />
    <OurStory />
    <Team />
    <Gallery />
    <Offer />
    <Reservation />
    <Contact />
    <Footer />
    <v-snackbar v-model="snackbar" timeout="-1">
      {{ text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import Navigation from './../components/Navigation';
import Landing from './../components/Landing';
import OurStory from './../components/OurStory';
import Gallery from './../components/Gallery';
import Team from './../components/Team';
import Offer from './../components/Offer';
import Contact from './../components/Contact';
import Reservation from "./../components/Reservation";
import Footer from './../components/Footer';

export default {
  components: {
    Navigation,
    Landing,
    OurStory,
    Gallery,
    Team,
    Offer,
    Reservation,
    Contact,
    Footer,
  },
  data: () => ({
    snackbar: false,
    text: ``,
  }),
};
</script>
