import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
let overrideRipple = {
  directives:{
    ripple:{
      inserted: ()=> {
      }
    }
  }
 }
 Vue.mixin(overrideRipple);
export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#DAA520',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#b71c1c',
        },
      },
    },
  })