<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, index) in images"
        :key="index"
        cols="12"
        sm="6"
        lg="4"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card flat class="rounded-0">
            <v-img
              :src="item.big"
              :lazy-src="item.small"
              class="gallery-image"
              aspect-ratio="1"
              max-width="400"
              :class="hover ? 'zoom' : ''"
              @click="openOverlay(item, index)"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-overlay
      opacity="0.8"
      :value="overlay"
      class="text-right"
      style="min-width: 90vw"
    >
      <v-main>
        <v-btn class="button mtb-3" fab @click="openPrev()">
          <v-icon>fas fa-arrow-circle-left</v-icon>
        </v-btn>
        <v-btn fab class="button mtb-3" @click="overlay = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-btn fab class="button mtb-3" @click="openNext()">
          <v-icon>fas fa-arrow-circle-right</v-icon>
        </v-btn>
        <v-img
          :src="overlayImage.full"
          :lazy-src="overlayImage.big"
          @click="overlay = false"
          style="min-width: 90vw; margin-top: 5px"
        />
      </v-main>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "Gallery",
  created() {
    let that = this;

    document.addEventListener("keyup", function(evt) {
      switch (evt.key) {
        case "Escape":
          that.overlay = false;
          return;
        case "ArrowRight":
          that.openNext()
          return;
        case "ArrowLeft":
          that.openPrev();
          return;
      } 
    });
  },
  data() {
    return {
      overlay: false,
      currentIndex: -1,
      overlayImage: {},
      images: [
        {
          big: require("../assets/gallery/big/1.webp"),
          full: require("../assets/gallery/full/1.webp"),
          small: require("../assets/gallery/small/1.webp"),
        },
        {
          big: require("../assets/gallery/big/2.webp"),
          full: require("../assets/gallery/full/2.webp"),
          small: require("../assets/gallery/small/2.webp"),
        },
        {
          big: require("../assets/gallery/big/3.webp"),
          full: require("../assets/gallery/full/3.webp"),
          small: require("../assets/gallery/small/3.webp"),
        },
        {
          big: require("../assets/gallery/big/4.webp"),
          full: require("../assets/gallery/full/4.webp"),
          small: require("../assets/gallery/small/4.webp"),
        },
        {
          big: require("../assets/gallery/big/5.webp"),
          full: require("../assets/gallery/full/5.webp"),
          small: require("../assets/gallery/small/5.webp"),
        },
        {
          big: require("../assets/gallery/big/6.webp"),
          full: require("../assets/gallery/full/6.webp"),
          small: require("../assets/gallery/small/6.webp"),
        },
        {
          big: require("../assets/gallery/big/7.webp"),
          full: require("../assets/gallery/full/7.webp"),
          small: require("../assets/gallery/small/7.webp"),
        },
        {
          big: require("../assets/gallery/big/8.webp"),
          full: require("../assets/gallery/full/8.webp"),
          small: require("../assets/gallery/small/8.webp"),
        },
        {
          big: require("../assets/gallery/big/9.webp"),
          full: require("../assets/gallery/full/9.webp"),
          small: require("../assets/gallery/small/9.webp"),
        },
      ],
    };
  },
  methods: {
    openOverlay(item, index) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return;
      }
      this.overlay = true;
      this.currentIndex = index;
      this.overlayImage = { full: item.full, big: item.big };
    },
    openPrev() {
      let index = this.currentIndex - 1;
      if (index < 0) {
        index = this.images.length - 1;
      }
      this.openOverlay(this.images[index], index);
    },
    openNext() {
      let index = this.currentIndex + 1;
      if (index >= this.images.length) {
        index = 0;
      }
      this.openOverlay(this.images[index], index);
    },
  },
};
</script>

<style lang="scss">
.gallery-image {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .gallery-image {
    margin: 0 auto;
  }
}
.v-image__image {
  transition: 1000ms transform;
}
.zoom .v-image__image {
  transform: scale(1.05);
}
.button {
  border: 0;
}
</style>
