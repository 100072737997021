import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import HomeDe from "../views/HomeDe.vue";
import Admin from "../views/Admin.vue";
import HomeEn from "../views/HomeEn.vue";
import NotFoundPage from "../views/NotFoundPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/si",
    name: "homeSi",
    component: Home,
  },
  {
    path: "/de",
    name: "homeDe",
    component: HomeDe,
  },
  {
    path: "/en",
    name: "homeEn",
    component: HomeEn,
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      title: "Gostilna Mencinger - Admin",
    },
    component: Admin,
  },
  {
    path: "*",
    component: NotFoundPage,
    meta: {
      title: "Not Found",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
