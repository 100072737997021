<template>
  <div>
    <h2 class="mt-5">Rezervacije</h2>
    <v-data-table
      :headers="headers"
      :items="reservations"
      :items-per-page="50"
      class="elevation-1"
      disable-sort
    >
    </v-data-table>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";
// import Cosmic from "@cosmicjs/sdk";

// const api = Cosmic();
// const bucket = api.bucket({
//   slug: "mencinger",
//   read_key: "D2NTh3jtdF1b3sKtCEMMZD3CO9dsFfCCQ1w2HRX3BGoiYYVixI",
// });
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Ime in priimek", value: "name"},
        { text: "Telefonska številka", value: "phone_number"},
        { text: "Datum", value: "date" },
        { text: "Ura", value: "time" },
        { text: "Število oseb", value: "number_of_people" },
        { text: "Komentar", value: "comment" },
      ],
      reservations: [],
    };
  },
  mounted() {
    if (getAuth().currentUser === null) {
      this.$actions.logout();
      return;
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      // bucket
      //   .getObjects({
      //     query: {
      //       type: "reservations",
      //     },
      //     props: "id,slug,title,content,metadata", // Limit the API response data by props
      //     sort: "-created_at",
      //   })
      //   .then((data) => {
      //     this.loading = false;
      //     data.objects.map((item) => {
      //       this.reservations.push({title: item.title, ...item.metadata})
      //     })
      //   });
    },
  },
};
</script>