<template>
  <v-app light>
      <router-view></router-view>
  </v-app>
</template>

<script>
import globalTranslations from "./locales/global";

export default {
  i18n: {
    locale: "si",
    sharedMessages: globalTranslations,
  },
  name: "App",
  components: {},
  created() {
    const path = this.$router.currentRoute.path.slice(1, 3);
    if (path !== "") {
      this.currentPage = path;
    } else {
      this.currentPage = this.detectLanguage();
    }
    this.$i18n.locale = this.currentPage;
  },
  methods: {
    detectLanguage() {
      const langCode = navigator.language.toLowerCase();
      let lang = 'si';
      if (langCode.indexOf("-") > -1) {
        lang = langCode.split("-")[1];
      } else {
        lang = langCode;
      }
      if(['si','de'].indexOf(lang) > -1) return lang;

      return 'si';
    },
  }
};
</script>

<style lang="scss">
@import "./styles/main.scss";
</style>