<template>
  <v-app>
    <Login v-if="!loggedIn" />
    <div v-else>
      <v-app-bar dense dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Gostilna Mencinger - Admin</v-toolbar-title>

        <v-spacer></v-spacer>
        <router-link to="/" style="margin-right: 20px"
          >Nazaj na stran</router-link
        >
        <v-btn color="secondary" @click="logout"> Odjava </v-btn>
      </v-app-bar>
      <v-container dark>
        <v-main>
          <Lunches />
          <Reservations />
          <router-view></router-view>
        </v-main>
      </v-container>
    </div>
  </v-app>
</template>
<script>
import Login from "./Login.vue";
import Lunches from "./Lunches.vue";
import Reservations from "./Reservations.vue";

export default {
  components: {
    Login,
    Lunches,
    Reservations,
  },
  data() {
    return {
      user: false,
    };
  },
  mounted() {
    if (localStorage.user) {
      this.user = localStorage.user;
    }
  },
  methods: {
    logout() {
      this.$actions.logout();
    },
  },
  computed: {
    loggedIn() {
      return this.$store.user !== null;
    },
    userInfo() {
      return this.$store.user;
    },
  },
};
</script>
