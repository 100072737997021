<template>
  <v-app>
    <Navigation />
    <Landing />
    <OurStory />
    <Lunch />
    <Gallery />
    <Team />
    <Offer />
    <Contact />
    <Footer />
  </v-app>
</template>

<script>
import Navigation from './../components/Navigation';
import Landing from './../components/Landing';
import OurStory from './../components/OurStory';
import Lunch from './../components/Lunch';
import Gallery from './../components/Gallery';
import Team from './../components/Team';
import Offer from './../components/Offer';
import Contact from './../components/Contact';
import Footer from './../components/Footer';
export default {
  components: {
    Navigation,
    Landing,
    OurStory,
    Lunch,
    Gallery,
    Team,
    Offer,
    Contact,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
