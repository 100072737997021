import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import { initializeApp } from "firebase/app";
import "vuetify/dist/vuetify.min.css";

Vue.use(VueI18n);
Vue.config.productionTip = false;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "si", // set locale
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("uppercase", function (value) {
  if (!value) return "";
  return value.valuetoUpperCase();
});

const firebaseConfig = {
  apiKey: "AIzaSyBJjih64C3C2Rie2aPbBO4YCfL76ZvobtI",
  authDomain: "mencinger-1d3a1.firebaseapp.com",
  projectId: "mencinger-1d3a1",
  storageBucket: "mencinger-1d3a1.appspot.com",
  messagingSenderId: "891231201084",
  appId: "1:891231201084:web:b1ec241a5f4d6bad8fe039",
};
initializeApp(firebaseConfig);

const store = Vue.observable({ user: null, specialWeek: true, tokenId: "" });

/* Create centralized actions for updating the store */
const actions = {
  login(user) {
    store.user = user;
    localStorage.user = JSON.stringify(user);
  },
  logout() {
    store.user = null;
    localStorage.user = null;
  },
};

/*
    Attach store and actions to the Vue prototype
    so they can be accessed from any component
*/
Vue.prototype.$store = store;
Vue.prototype.$actions = actions;

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
  mounted: () => {
    document.dispatchEvent(new Event("x-app-rendered"));
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);
      // check if token expired
      store.user = user;
    }
  },
}).$mount("#app");

// Your web app's Firebase configuration

// Initialize Firebase
