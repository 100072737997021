<template>
  <v-main>
    <v-container id="reservation">
      <v-layout column fluid class="mb-6">
        <v-card-title class="pb-12">
          <h1 class="modernline">Rezerviraj mizo</h1>
        </v-card-title>
        <v-form v-if="sent == false" v-model="valid" :disabled="sending">
          <v-row>
            <v-col>
              <v-text-field
                filled
                v-model="form.name"
                label="Ime in priimek"
                :rules="[rules.required]"
              />
              <v-text-field
                filled
                v-model="form.phone_number"
                label="Telefonska številka"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    filled
                    label="Datum"
                    v-bind="attrs"
                    @blur="form.date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  locale="sl"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form.date)"
                  >
                    {{ $t("OK") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-row>
                <v-col>
                  <v-select
                    filled
                    :items="timeItems"
                    v-model="form.time"
                    label="Ura"
                  ></v-select> </v-col
                ><v-col>
                  <v-text-field
                    filled
                    type="number"
                    v-model="form.number_of_people"
                    label="Število oseb"
                    :rules="[rules.required, rules.number]"
                  />
                  <v-text-field
                    filled
                    class="d-none"
                    v-model="form.check"
                    label=""
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-textarea
                no-resize
                filled
                v-model="form.comment"
                rows="1"
                label="Dodatno sporočilo"
              />
              <v-btn
                block
                color="primary"
                :disabled="!valid"
                height="58px"
                @click="submit"
                :loading="sending"
                >Rezerviraj</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <v-content v-if="sent == true"
          >Rezervacija poslana, za potrditev vas bomo kontaktirali</v-content
        >
      </v-layout>
      <v-snackbar v-model="snackbar" color="red">
        {{ error }}
      </v-snackbar>
    </v-container>
    <v-parallax class="parallax" light :src="parallax" height="350">
      <v-layout align-center column justify-center> </v-layout>
    </v-parallax>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Team",
  created() {
    for (let i = 9; i < 21; i++) {
      for (let j = 0; j < 4; j++) {
        this.timeItems.push(`${i}:${j === 0 ? `00` : 15 * j}`);
      }
    }
  },
  data() {
    return {
      valid: false,
      sending: false,
      sent: false,
      snackbar: false,
      error: "",
      menu: false,
      dateFormatted: this.formatDate(moment().format("YYYY-MM-DD")),
      form: {
        name: "",
        phone_number: "",
        date: moment().format("YYYY-MM-DD"),
        time: "12:00",
        number_of_people: 2,
        comment: "",
        check: "",
      },
      timeItems: [],
      rules: {
        required: (value) => !!value,
        number: (value) => /^\d+$/.test(value) || "",
        phone: (value) => {
          const pattern = /^(\+0?\s)?\(?\d{3}\)?[\s.-]\d{3}[\s]\d{3}$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      parallax: require("../assets/content/contact-parallax.webp"),
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.form.date);
    },
  },
  watch: {
    menu() {
      this.dateFormatted = this.formatDate(this.form.date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD.MM.YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
    },
    submit() {
      this.sending = true;

      const params = new URLSearchParams();
      Object.entries(this.form).forEach(([key, value]) => {
        params.append(key, value);
      });

      axios
        .post("https://diti.si/api/mencinger/reservations/create", params)
        .then((res) => {
          if (res.status === 200) {
            this.sent = true;
          } else {
            this.snackbar = true;
            this.error = res.response;
          }
        })
        .catch(() => {
          this.snackbar = true;
          this.sent = false;
          this.error = this.$t("error");
        })
        .then(() => {
          this.sending = false;
        });
    },
  },
};
</script>
