<template>
  <v-app>
    <Navigation />
    <Landing />
    <OurStory />
    <Lunch />
    <Team />
    <Gallery />
    <Offer />
    <Reservation />
    <Contact />
    <Footer />
    <v-snackbar v-model="snackbar" timeout="-1">
      {{ text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import Navigation from "./../components/Navigation";
import Landing from "./../components/Landing";
import OurStory from "./../components/OurStory";
import Lunch from "./../components/Lunch";
import Gallery from "./../components/Gallery";
import Team from "./../components/Team";
import Offer from "./../components/Offer";
import Reservation from "./../components/Reservation";
import Contact from "./../components/Contact";
import Footer from "./../components/Footer";

export default {
  components: {
    Navigation,
    Landing,
    OurStory,
    Lunch,
    Gallery,
    Team,
    Offer,
    Reservation,
    Contact,
    Footer,
  },
  data: () => ({
    snackbar: false,
    text: `Od 29.7. do 12.8. smo na dopustu. Se vidimo spet 13.8.`,
  }),
};
</script>
