<template>
  <v-container id="ourstory">
    <v-layout column fluid class="mb-6">
      <v-flex class="text-xs-center my-5">
        <h2 class="modernline p-0">Gostilna Mencinger</h2>
      </v-flex>
      <v-row>
        <v-col cols="12" md="8">
         <div v-html="$t('ABOUT')"></div>
          <strong>Matej Štefanec, chef</strong>
        </v-col>
        <v-col cols="12" md="4">
          <v-img :src="mencinger" aspect-ratio="1" />
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import globalTranslations from "../locales/global";

export default {
  i18n: {
    locale: "si",
    sharedMessages: globalTranslations,
  },
  name: "OurStory",
  data() {
    return {
      mencinger: require("../assets/content/our-story.webp"),
    };
  },
};
</script>
