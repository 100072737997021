<template>
  <div>
    <v-app-bar fixed :color="color" class="navigation">
      <v-toolbar-title
        class="page-title modernline"
        @click="$vuetify.goTo('#landing', options)"
        >{{ appTitle }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <template
          v-for="(item, index) in navigationItems"
        >
          <v-btn
            v-if="item.title[currentPage] !== false"
            text
            :key="index"
            @click="$vuetify.goTo(item.link, options)"
            :class="item.bold ? 'bold' : ''"
            >{{ item.title[currentPage] }}</v-btn
          >
        </template>
      </div>
      <div class="hidden-md-and-up">
        <v-menu offset-y left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark icon v-bind="attrs" v-on="on">
              <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in navigationItems"
              :key="index"
              @click="$vuetify.goTo(item.link, options)"
            >
              <v-list-item-title>{{
                item.title[currentPage]
              }}</v-list-item-title>
            </v-list-item>
            <!-- Language selection -->
            <v-divider></v-divider>
            <v-list-item
              v-for="(language, index) in languages"
              :key="index"
              @click="changeLanguage(language)"
            >
              <v-list-item-title>{{ language.text }}</v-list-item-title>
            </v-list-item>
            <!-- Language selection -->
          </v-list>
        </v-menu>
      </div>
      <!-- Language selection -->
      <v-menu offset-y left transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text class="language">{{
            currentPage
          }}</v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(language, index) in languages"
            :key="index"
            @click="changeLanguage(language)"
          >
            <v-list-item-title>{{ language.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Language selection -->
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data: () => ({
    color: "rgba(0, 0, 0, 0)",
    height: 199,
    appTitle: "Gostilna Mencinger",
    currentPage: "si",
    drawer: false,
    navigationItems: [
      {
        title: { si: "Naša zgodba", us: "Naša zgodba", de: "Unsere geschichte" },
        link: "#ourstory",
      },
      { title: { si: "Malice", us: false, de: false }, link: "#lunch" },
      { title: { si: "Ekipa", us: "Ekipa", de: "Über uns" }, link: "#team" },
      {
        title: { si: "Ponudba", us: "Ponudba", de: "Speisekarte" },
        link: "#offer",
      },
      {
        title: { si: "Rezervacija", us: "Rezervacija", de: "Reservierung" },
        link: "#reservation",
      },
      {
        title: { si: "Kontakt", us: "Kontakt", de: "Kontakt" },
        link: "#contact",
      },
    ],
    languages: [
      {
        text: "Slovensko",
        code: "si",
      },
      {
        text: "Deutsch",
        code: "de",
      },
    ],
  }),
  computed: {
    options() {
      return {
        duration: 750,
        easing: "easeOutQuint",
        offset: 70,
      };
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    const path = this.$router.currentRoute.path.slice(1, 3);
    if (path !== "") {
      this.currentPage = path;
    } else {
      this.currentPage = this.detectLanguage();
    }
    this.$i18n.locale = this.currentPage;
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    detectLanguage() {
      const langCode = navigator.language.toLowerCase();
      let lang = 'si';
      if (langCode.indexOf("-") > -1) {
        lang = langCode.split("-")[1];
      } else {
        lang = langCode;
      }
      if(['si','de'].indexOf(lang) > -1) return lang;

      return 'si';
    },
    handleScroll() {
      if (window.pageYOffset > 150) {
        const alpha = Math.min(window.pageYOffset / 500, 0.9);
        this.color = `rgba(37, 37, 37, ${alpha})`;
      } else {
        this.color = "rgba(0, 0, 0, 0)";
      }
    },
    changeLanguage(language) {
      const newRoute = `/${language.code}`;
      this.$i18n.locale = language.code;
      if (newRoute !== this.$router.currentRoute.path) {
        // this.$vuetify.goTo("#landing"); // temp fix for color of header
        this.$router.push({ path: newRoute });
      }
    },
    selectedLanguage() {
      return this.$route.params;
    },
  },
};
</script>

<style scoped>
header.v-sheet.v-app-bar.v-toolbar {
  border: none;
  box-shadow: none;
}
header button.theme--light.v-btn {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}
header {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: all 200ms linear;
}
.page-title {
  color: #fff;
  font-size: 1.2rem;
  line-height: 3.6rem;
  cursor: pointer;
}
header button.theme--light.v-btn.language {
  background: rgba(255, 255, 255, 0.7);
  color: #333;
  min-width: 40px;
}
@media only screen and (min-width: 768px) {
  .page-title {
    font-size: 1.6rem;
  }
}
</style>
<style>
.bold .v-btn__content {
  color: #daa520;
  font-weight: 700;
}
.language {
  margin-left: 40px;
}
</style>
