<template>
  <!--<v-container fluid fill-height id="landing" class="landing">
    <video autoplay muted loop id="video">
      <source :src="landing" type="video/mp4">
    </video>
    <v-layout justify-center align-center column class="landing-content" >

      <h1 class="white--text text-center modernline hidden-sm-and-down">
        Gostilna Mencinger
      </h1>
      <h2 class="white--text text-center mt-16">
      <i18n path="LANDING_TEXT" tag="p">
        <br />
      </i18n>
      </h2>
    </v-layout>
  </v-container>-->
  <v-container fluid fill-height id="landing"  class="video-header">
  <video :src="landing" autoplay loop preload muted poster=""></video>
  <v-layout justify-center align-center column class="viewport-header">
    <h1 class="white--text text-center modernline hidden-sm-and-down">
        Gostilna Mencinger
      </h1>
      <h2 class="white--text text-center mt-16">
      <i18n path="LANDING_TEXT" tag="p">
        <br />
      </i18n>
      </h2>
  </v-layout>
</v-container>
</template>

<script>
import globalTranslations from "../locales/global";

export default {
  i18n: {
    locale: "si",
    sharedMessages: globalTranslations,
  },
  data() {
    return {
      landing: require("../assets/content/landing/landing.webm"),
    };
  },
  computed: {
    options() {
      return {
        duration: 750,
        easing: "easeOutQuint",
        offset: 70,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin coverer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.video-header {
  text-align: center;
  width: 100vw;
  height: 100vh;
  &, video, .viewport-header {
    @include coverer;
  }
  video {
    background: brown;
    object-fit: cover;
  }
  .viewport-header {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2
  }
}
.video-header {
  position: relative;
  width: 100%;
}

.video-header::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
 background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
}

</style>
