<template>
  <v-container id="contact">
    <v-layout column fluid class="mb-6">
      <v-card-title class="py-12">
        <h1 class="modernline">{{$t("contact.question")}}</h1>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <v-form v-if="sent == false" v-model="valid" :disabled="sending">
            <v-flex xs12>
              <v-text-field
                filled
                v-model="name"
                :rules="requiredRule"
                :placeholder="$t('contact.name')"
              ></v-text-field>
              <v-text-field
                filled
                v-model="email"
                :rules="emailRules"
                :placeholder="$t('contact.email')"
                >></v-text-field
              >
            </v-flex>
            <v-flex>
              <v-textarea
                filled
                v-model="message"
                :rules="requiredRule"
                no-resize
                name="message"
                :label="$t('contact.message')"
              ></v-textarea>
            </v-flex>
            <v-btn
              block
              color="primary"
              :disabled="!valid"
              @click="submit"
              :loading="sending"
              >{{$t("contact.send")}}</v-btn
            >
          </v-form>
          <v-alert
            v-else
            border="left"
            color="#daa520"
            dark
            dense
            icon="mdi-check"
            class="py-6"
          >
            {{$t("contact.sent")}}
          </v-alert>
        </v-col>
        <v-col cols="12" sm="6" class="gmaps d-flex" v-if="displayMaps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10953.712465774006!2d15.9931667!3d46.6578082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6765e120e5e5f8a0!2sGostilna%20Mencinger%2C%20Matej%20%C5%A0tefanec%2C%20s.p.!5e0!3m2!1sen!2ssi!4v1600201516586!5m2!1sen!2ssi"
            height="402"
            width="100%"
            frameborder="0"
            style="border:0;"
            allowfullscreen
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </v-col>
      </v-row>
    </v-layout>
    <v-snackbar v-model="snackbar" color="red">
      {{ error }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globalTranslations from "../locales/global";

export default {
  i18n: {
    locale: "si",
    sharedMessages: globalTranslations,
  },
  mounted() {
    this.delayedShow();
  },
  data: () => ({
    valid: false,
    sending: false,
    sent: false,
    snackbar: false,
    displayMaps: false,
    error: "",
    name: "",
    email: "",
    message: "",
    requiredRule: [(v) => !!v],
    emailRules: [
      (v) => !!v,
      (v) => /.+@.+/.test(v),
    ],
  }),
  methods: {
    delayedShow() {
      setTimeout(() => {
        this.displayMaps = true;
      }, 1000);
    },
    submit() {
      this.sending = true;
      const params = new URLSearchParams();
      params.append("email", this.email);
      params.append("target", 2);
      params.append("html", this.message);
      params.append("subject", `Kontakt spletne strani - ${this.name}`);
      axios
        .post("https://diti.si/api/email/send", params)
        .then((res) => {
          if (res.status === 200) {
            this.sent = true;
          } else {
            this.snackbar = true;
            this.error = res.response;
          }
        })
        .catch(() => {
          this.snackbar = true;
          this.sent = false;
          this.error = this.$t("error");
        })
        .then(() => {
          this.sending = false;
        });
    },
  },
};
</script>
<style>
h2 {
  margin-bottom: 3rem;
}
</style>
