<template>
  <v-container id="offer">
    <v-layout column fluid class="mb-6">
      <v-card-title class="text-center justify-center py-1 py-md-12">
        <h1 class="modernline hidden-sm-and-down">{{$t("OUR_OFFER")}}</h1>
        <h2 class="modernline hidden-md-and-up">Naša stalna ponudba</h2>
      </v-card-title>

      <v-tabs v-model="tab" centered fixed-tabs>
        <v-tab
          v-for="(item, index) in items"
          :key="index"
          v-html="item.title[currentPage]"
        ></v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <v-row>
            <v-col cols="12" sm="12">
              <v-list flat disabled>
                <v-list-item-group>
                  <v-list-item
                    v-for="(content, index) in item.content"
                    :key="index"
                  >
                    <v-list-item-content two-line>
                      <v-list-item-title class="item-title">{{
                        content[currentPage].title
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-html="content[currentPage].ingredients"
                        class="item-ingredients"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-html="content[currentPage].description"
                        class="item-description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <!--<v-col cols="12" sm="4" class="d-flex">
              <v-img :src="item.image" aspect-ratio="1" />
            </v-col>-->
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
  </v-container>
</template>

<script>
import globalTranslations from "../locales/global";

export default {
  i18n: {
    locale: "si",
    sharedMessages: globalTranslations,
  },
   created() {
    const path = this.$router.currentRoute.path.slice(1, 3);
    if (path !== "") {
      this.currentPage = path;
    }
  },
  data() {
    return {
      tab: null,
      currentPage: "si",
      items: [
        {
          title: { si: "Hladne predjedi", de: "Kalte Vorspeisen" },
          image: require("../assets/gallery/big/1.webp"),
          content: [
            {
              de: {
                title: "Fleisch aus der 'Tünke', Schmalz, Kren"
              },
              si: {
                title: "Meso iz tunke, zaseka, hren",
              },
            },
          ],
        },
        {
          title: {
            si: "Glavne jedi",
            de: "Hauptspeisen"
          },
          image: require("../assets/gallery/big/2.webp"),
          content: [
            {
              de: {
                title: "Kürbis Gnocchi",
                ingredients: "Salbeisoße, Kürbiskernöl, Samenmischung"
              },
              si: {
                title: "Bučni njoki, žajbljeva omaka, bučno olje, semenke",
              },
            },
            {
              de: {
                title: "Schnitzel nach Wahl",
                ingredients: "gebraten, in Natursoße, auf dem Grill"
              },
              si: {
                title: "Zrezek po izbiri",
                ingredients: "Ocvrt, v naravni omaki, na žaru",
              },
            },
            {
              de: {
                title: "Rinderfilet",
              },
              si: {
                title: "Goveji file",
              },
            },
            {
              de: {
                title: "Rinder Ramsteak",
              },
              si: {
                title: "Goveji ramstek",
              },
            },
            {
              de: {
                title: "Kalbfleisch",
              },
              si: {
                title: "Teletina",
              },
            },
            {
              de: {
                title: "Kalbs Tomahawk",
              },
              si: {
                title: "Telečji Tomahawk",
              },
            },
            {
              de: {
                title: "Schwein Tomahawk Steak",
              },
              si: {
                title: "Svinjska zarebernica",
              },
            },
            {
              de: {
                title: "Eintenbrust",
              },
              si: {
                title: "Račje prsi",
              },
            },
            {
              de: {
                title: "Für Kinder",
                ingredients: 'Kekec Schnitzel mit Beilage'
              },
              si: {
                title: "Za otroke",
                ingredients: "Zrezek Kekec s prilogo"
              },
            },
          ],
        },
        {
          title: {
            si: "Sladki del",
            de: "Der süße Teil"
          },
          image: require("../assets/gallery/big/5.webp"),
          content: [
            {
              de: {
                title: "Hausgemachte 'Prleška Gibanica'",
              },
              si: {
                title: "Domača prleška gibanica",
              },
            },
            {
              de: {
                title: "Hausgemachter Strudel",
              },
              si: {
                title: "Domači zavitek",
              },
            },
          ],
        },
        {
          title: {
            si: "Priloge",
            de: "Beilagen"
          },
          image: require("../assets/gallery/big/3.webp"),
          content: [
            {
              de: {
                title: "Röstkartoffeln",
              },
              si: {
                title: "Pražen krompir",
              },
            },
            {
              de: {
                title: "Buchweizensterz mit Grameln"
              },
              si: {
                title: "Ajdovi žganci z ocvirki",
              },
            },
            {
              de: {
                title: "Reis",
              },
              si: {
                title: "Riž",
              },
            },
            {
              de: {
                title: "Pommes",
              },
              si: {
                title: "Pomfri",
              },
            },
            {
              de: {
                title: "Gebratenes Gemüse",
              },
              si: {
                title: "Popečena zelenjava",
              },
            },
            {
              de: {
                title: "Hausgemachte Topfenstudel",
              },
              si: {
                title: "Skutni štruklji",
              },
            },
          ],
        },
      ],
    };
  },
};
</script>

<style type="scss" scoped>
.item-title {
  margin-bottom: 0.4rem;
  line-height: 1.5rem;
}
.item-ingredients {
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
}
.item-description {
  color: #222 !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
</style>