<template>
  <div>
    <h1>You have no power here</h1>
  </div>
</template>

<script>
export default {
  name: "notFoundPage",
};
</script>
