export default {
  de: {
    "LANDING_TEXT": `Wir verwöhnen Sie mit Speisen, die ein Teil unserer Region und unserer
    Geschichte sind. {0}Unsere Gerichte zeichnen sich durch ihre Frische und moderne
    Art der Zubereitung aus.
    `,
    "ABOUT": `<p>Das Haus mit den dazugehörigen Wirtschaftsgebäuden (der Stall, das Presshaus,
      der Weinkeller, die Brennerei) begann Anfang des 20. Jahrhunderts auch den
      gastronomischen Zwecken zu dienen. Das Anwesen wurde in den 1930er Jahren
      von der Familie Mencinger gekauft, die aus den USA, genauer gesagt aus
      Pennsylvania, zurückkehrte, wohin sie von Prekmurje aus ausgewandert war.
      Urgroßvater Ivan und Urgroßmutter Neža kümmerten sich mit ihrer jungen
      Familie um den Bauernhof und später auch um das Gasthaus. 
      </p>
      <p>
      In den 1950er Jahren übernahmen Sohn Alojz und die Schwiegertochter Marija (meine
      Großeltern) das Gasthaus. In den nächsten 30 Jahren war das Gasthaus für seine
      traditionelle pannonische Küche: Saure Suppe, Kübelfleisch, geräucherte
      Schweinshachse, Rindsgulasch und die Potitze - das traditionelle slowenische
      Gebäck aus gerolltem Hefeteig und verschiedenen Füllungen, bekannt.
      Nach einer kürzeren Pause eröffneten in den 1990er Jahren Tochter Danica und
      ihr Ehemann Stanislav (meine Eltern) das Gasthaus wieder. Das Gasthaus
      beginnt den neuen kulinarischen Trends zu folgen – dementsprechend wurden
      auch die traditionellen Rezepte neu interpretiert.
      </p><p>
    Im Jahr 2010 trat ich selbst zum Team des Gasthofes bei und führe seitdem mit
    Hilfe meiner Eltern und meiner Großmutter Marija die Tradition schon in vierter
    Generation weiter.
    </p><p>
    Mit einem jungen Team gestalten wir das Ambiente und erschaffen Momente,
    die in der heutigen, von Hektik und Stress geprägten Zeit, zum Genießen,
    Lernen und Entspannen einladen.
    </p>`,
    "TEAM": "Mencinger Team",
    "TEAM_TEXT": `<p>Die Gaumenfreuden werden von unserer Köchin Cvetka zubereitet, die ihren
    Beruf schon seit 30 Jahren gekonnt ausübt. Die ausgewählten Worte und
    Gewürze unseres Chefs Matej runden die Gerichte ab.</p>
    <p>
    Wir stecken unser Herz und unsere Seele in jedes Gericht, das wir kreieren.
    Wir folgen der Tradition, entwickeln neue Geschmäcke und kombinieren sie.
    Unser ganzer Stolz sind der Gemüsegarten unserer Großmutter und der
    Obstgarten unserer Mutter Danica. Ganz besonders stolz sind wir aber auf die
    Hühnermägen Suppe unserer Großmutter.
    </p><p>
    Rindssuppe, Kalbsbraten, verschiedene Strudel- und Kuchenarten, Gibanica -
    ein traditioneller slowenischer Kuchen mit verschiedenen Füllungen, sowie
    frisches Brot aus dem Ofen dürfen bei uns nicht fehlen.
    Bei uns legen wir einen großen Wert auf saisonales und lokales Angebot.
    Vegetarische Gerichte, wie zum Beispiel Kürbisgnocchi, stehen Ihnen immer
    zur Auswahl.
    </p>`,
    "OUR_OFFER": "Unser Dauerangebot",
    "contact": {
      "question": "Fragen?",
      "name": "Vor- und Nachname",
      "email": "E-mail",
      "message": "Nachricht",
      "send": "Verschicken",
      "sent": "Vielen Dank für Ihr Interesse, die Frage wurde erfolgreich verschickt."
    },
    "error": "Ups, etwas ist schief gelaufen. Bitte versuche es erneut.",
  },
  si: {
    "LANDING_TEXT": `Pogostimo vas z jedmi, ki so del našega okolja, naše zgodovine,{0}
    na način, ki pritegne s svežino in lahkotnostjo današnjega časa.`,
    "ABOUT": `<p>Hiša s pripadajočimi gospodarskimi poslopji (hlev, preša, vinska klet, žganjekuha) je v začetku 20 st. začela služiti tudi gostinstvu. Posestvo je v 30. letih kupila družina Mencinger, ki se je vrnila iz ZDA, natančneje iz Pensilvanije, kamor je migrirala iz Prekmurja. Pradedek Ivan in prababica Neža sta z mlado družino prevzela skrb za kmetijo in kasneje tudi gostilno. V 50. letih sta gostilno prevzela sin Alojz in snaha Marija (moj dedek in babica). Naslednjih 30 let je gostilna slovela po tradicionalni panonski kuhinji: kisli juhi, tunki, prekajeni svinjski krači, govejem golažu in potici.</p>
    <p>Po krajšem premoru v 90-tih letih hčerka Danica in mož Stanislav (moja starša) na novo odpreta vrata gostilne ter nadaljujeta tradicijo uporabe starih receptov, preoblikovanih v novo podobo… Gostilna začne slediti novim trendom v koraku s časom.</p>
    <p>Leta 2010 se ekipi družinske gostilne pridružim tudi sam, ki ob pomoči staršev in babice Marije nadaljujem napisane in doživete zgodbe že četrte generacije. Z mlado ekipo ustvarjamo prostor in trenutke, kjer se uživa, uči in sprosti od vrveža današnjega časa.</p>`,
    "TEAM": "Ekipa Mencinger",
    "TEAM_TEXT": `<p>
    Dobrote iz kuhinje prihajajo izpod ustvarjalnih rok naše Cvetke, ki
    viha kuhalnico že polnih 30 let, pospremljeno z besednimi in
    izbranimi začimbami vodje kolektiva - šefa Mateja.
  </p>
  <p>Naša ustvarjalnost je prepojena z dušo in srcem.</p>
  <p>
    Sledimo tradiciji, razvijamo okuse in kombiniranje le-teh. Še vedno
    smo ponosni na babičino kislo juho z želodčki, na njene dobrote z
    vrta in sadovnjaka mame Danice.
  </p>
  <p>
    Ne gre brez goveje juhe, telečje pečenke, štrudlov, gibanic in pit,
    ter svežega kruha iz domače peči.
  </p>
  <p>
    Naša pot nas vodi do sezonske in lokalne ponudbe. Za ljubitelje
    brezmesnih jedi pa se vedno najde kaj novega, tudi bučni njoki so
    vedno na voljo.
  </p>`,
    "OUR_OFFER": "Naša stalna ponudba",
    "contact": {
      "question": "Vprašanje?",
      "name": "Ime in priimek",
      "email": "E-mail",
      "message": "Sporočilo...",
      "send": "Pošlji",
      "sent": "Hvala za vaše zanimanje, vprašanje je bilo uspešno poslano.",
      "error": "Ups, nekaj se je zalomilo. Poskusite ponovno.",
    }
  },
  us: {
    "LANDING_TEXT": `Pogostimo vas z jedmi, ki so del našega okolja, naše zgodovine,{0}
      na način, ki pritegne s svežino in lahkotnostjo današnjega časa.`,
  }
}