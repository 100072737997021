export default {
    en: {
    },
    de: {
        "Vse pravice pridržane": "Alle Rechte vorbehalten",
        "Produkcija": "Produktion",
        "Odpiralni čas": "Öffnungszeiten",
        "Slovenija": "Slovenija",
        "Ponedeljek": "Montag",
        "Torek - Četrtek": "Dienstag – Donnerstag",
        "Torek – Petek": "Dienstag - Freitag",
        "Petek": "Freitag",
        "Sobota": "Samstag",
        "Nedelja": "Sontag",
        "Zaprto": "Geschlossen",
        "Naslov": " Addresse",
        "SHORT_FOOTER_DESCRIPTION": "Wir verwöhnen Sie mit Speisen, die Teil unserer Umwelt, unserer Geschichte sind, auf eine Weise, die mit der Frische und Leichtigkeit der heutigen Zeit lockt."

    }, 
    si: {
        "Vse pravice pridržane": "Vse pravice pridržane",
        "Produkcija": "Produkcija",
        "Ponedeljek": "Ponedeljek",
        "Odpiralni čas": "Odpiralni časi",
        "Slovenija": "Slovenija",
        "Kratka beseda": "Kratka beseda",
        "Torek – Četrtek": "Torek - Četrtek",
        "Torek – Petek": "Torek - Petek",
        "Petek": "Petek",
        "Sobota": "Sobota",
        "Nedelja": "Nedelja",
        "Zaprto": "Zaprto",
        "Naslov": " Naslov",
        "SHORT_FOOTER_DESCRIPTION": "Pogostimo vas z jedmi,  del našega okolja, naše zgodovine, na način, ki pritegne s svežino in lahkotnostjo današnjega časa."
    }
}