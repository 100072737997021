<template>
  <v-main dark>
    <v-container fluid fill-height class="login">
      <v-layout justify-center align-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-form @submit.prevent="submit">
              <v-toolbar dark>
                <v-toolbar-title>Gostilna Mencinger - Admin</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  name="login"
                  label="E-mail"
                  :rules="emailRules"
                  type="text"
                  v-model="email"
                ></v-text-field>
                <v-text-field
                  name="password"
                  label="Geslo"
                  type="password"
                  v-model="password"
                ></v-text-field>

                <v-alert type="error" v-if="error" v-html="error"></v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :loading="loading"
                  type="submit"
                  :disabled="!email || !password"
                  >Prijava</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      email: "tomi.mocnik@gmail.com",
      emailRules: [
        (v) => !!v || "Vnesi email",
        (v) => /.+@.+\..+/.test(v) || "E-mail neveljaven",
      ],
      password: "",
      error: null,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.error = "";
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
        .then((userCredential) => {
          this.$actions.login(userCredential.user);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.error = `<p><strong>Prijava ni uspela</strong></p><p><code>${error.message}</code></p>`;
        });
    },
  },
};
</script>
<style scoped>
.login {
  height: 100vh;
  max-width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url("../../assets/content/landing/landing.jpg") no-repeat center center
      fixed;
  background-size: cover;
}
</style>
